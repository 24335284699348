import styled from "styled-components";
import { useState } from "react";
import { useRecoilState } from "recoil";
import plusImg from "../../assets/scrap/plus.svg";
import { Flex } from "../../styles/landing";
import MakeCategoryPopup from "../diary/MakeCategoryPopup";
import { Overlay } from "../diary/HamburgerPopup";
import { HeaderText } from "../../pages/home/Mypage";
import editImg from "../../assets/diary/editDiary.svg";
import { isEditState } from "../../recoil/atoms";

const HeaderContainer = styled(Flex)`
  margin-top: 20px;
  margin-bottom: 12px;

  width: 100%;
  p {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 130% */
  }

  align-items: center;
  justify-content: center;
`;
const Text = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 24px;
  position: absolute;
  right: 64px;
`;
const Edit = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 64px;
`;
const Plus = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 24px;
`;
function ScrapHeader({ text }: { text: string }) {
  const [isCategoryPopup, setIsCategoryPopup] = useState(false);
  const [isEdit, setIsEdit] = useRecoilState(isEditState);

  const handlePopup = () => {
    setIsCategoryPopup(true);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  return (
    <HeaderContainer>
      {isCategoryPopup && (
        <>
          <Overlay onClick={() => setIsCategoryPopup(false)} />
          <MakeCategoryPopup setPostCategoryPopup={setIsCategoryPopup} />
        </>
      )}
      <HeaderText>{text}</HeaderText>
      {isEdit ? (
        <Text onClick={handleEdit}>완료</Text>
      ) : (
        <Edit onClick={handleEdit} src={editImg} />
      )}
      <Plus onClick={handlePopup} src={plusImg} />
    </HeaderContainer>
  );
}
export default ScrapHeader;
