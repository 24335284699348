import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { CurrentDateBox } from "../../components/calendar/YearMonthDropdown";
import Header from "../../components/layouts/Header";
import {
  WriteArea,
  WriteBox,
  WriteBtn,
} from "../../components/write/DirayTextArea";
import NumOfChar from "../../utils/\bNumOfChar";
import { patchDiary } from "../../apis/calandar-api";

function PatchDiary() {
  const locatoin = useLocation();
  const navigate = useNavigate();
  const info = locatoin.state;

  const diaryId = info.id;
  const [diaryContent, setDiaryContent] = useState(info.content);
  console.log(info);
  const handleContent = (e: any) => {
    setDiaryContent(e.target.value);
  };
  const handlePatch = () => {
    patchDiary(diaryId, { date: info.date, content: diaryContent })
      .then(() => {
        alert("수정이 완료되었습니다.");
        navigate("/calendar");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const lenderDate = () => {
    if (!info?.date) return ""; // date 값이 없는 경우 빈 문자열 반환
    const dateParts = info.date.split("-");
    return `${dateParts[0]}년 ${dateParts[1]}월 ${dateParts[2].split("T")[0]}일`;
  };
  return (
    <>
      <Header text="수정 취소" />
      <CurrentDateBox>{lenderDate()}</CurrentDateBox>
      <WriteBox>
        <WriteArea onChange={handleContent} defaultValue={info.content} />
        <NumOfChar content={diaryContent} />
        <WriteBtn onClick={handlePatch}>수정하기</WriteBtn>
      </WriteBox>
    </>
  );
}

export default PatchDiary;
