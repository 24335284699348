import { atom } from "recoil";

export const currentDateState = atom({
  key: "currentDate",
  default: new Date(),
});
export const datePopupState = atom({
  key: "datePopup",
  default: false,
});

export const yearMonthPopupState = atom({
  key: "yearMonthPopup",
  default: false,
});

export const reportIdState = atom({
  key: "reportId",
  default: -1,
});

export const userInfoState = atom({
  key: "userInfo",
  default: {
    id: 0,
    email: "",
    nickname: "",
    notificationTime: "",
    authProvider: "",
  },
});

export const periodState = atom({
  key: "period",
  default: "AM",
});

export const hourState = atom({
  key: "hour",
  default: "01",
});

export const minuteState = atom({
  key: "minute",
  default: "00",
});

export const audioState = atom({
  key: "audio",
  default: new Audio(""),
});

export const isEditState = atom({
  key: "isEdit",
  default: false,
});
