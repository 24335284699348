import styled from "styled-components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CurrentDateBox } from "../calendar/YearMonthDropdown";
import dropDown from "../../assets/calendar/dropDown.svg";
import { currentDateState, datePopupState } from "../../recoil/atoms";

const DateBox = styled(CurrentDateBox)`
  margin-left: 0;
`;
function DateDropdown() {
  const setDatePopup = useSetRecoilState(datePopupState);
  const currentDate = useRecoilValue(currentDateState);

  return (
    <DateBox
      onClick={() => {
        setDatePopup(true);
      }}
    >
      {currentDate.getDate()}일
      <img src={dropDown} alt="드롭다운 이미지" />
    </DateBox>
  );
}
export default DateDropdown;
