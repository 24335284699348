import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { dayString } from "../../assets/calendar/dayString";
import SelectYearMonthPopup from "../../components/calendar/SelectYearMonthPoup";
import {
  audioState,
  currentDateState,
  yearMonthPopupState,
} from "../../recoil/atoms";
import YearMonthDropdown from "../../components/calendar/YearMonthDropdown";
import tmpImg from "../../assets/calendar/tmpCalImg.png";
import { IDiaryInfo, getMonthDiaries } from "../../apis/calandar-api";
import { Flex } from "../../styles/landing";
// import { postPush } from "../../apis/user-api";

/* eslint-disable no-loop-func */

const ImgBox = styled.img`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #eee;
  margin-bottom: 4px;
`;
const CalendarBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: start;
  width: 364px;
  @media screen and (max-width: 364px) {
    width: 100%;
  }
`;
const CalendarHeader = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
`;
const DateBox = styled.div`
  width: 32px;
  height: 56px;
  text-align: center;
  margin: 10px;
  p {
    color: #797979;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px; /* 181.818% */
    letter-spacing: -0.275px;
  }
  @media screen and (max-width: 364px) {
    width: calc(100% / 7 - 6px); // 더 작은 너비에서 간격 조정
    margin: 3px;
  }
`;

const TodayBox = styled.div`
  margin-bottom: 4px;
  border-radius: 12px;
  background: linear-gradient(
    137deg,
    #ffe8e4 16.38%,
    #dde8e7 53.39%,
    #bee8e9 87.55%
  );
  width: 32px;
  height: 32px;
  font-size: 24px;
`;
const TodayText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px; /* 181.818% */
  background-color: #fc8871;
  border-radius: 8px;
`;
const Logo = styled.div`
  margin-left: 20px;
  color: #ffbeb1;
  margin-top: 20px;
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
`;
function Calendar() {
  const navigate = useNavigate();
  const [diaries, setDiaries] = useState<IDiaryInfo[]>([]);
  const [currentDate, setCurrentDate] = useRecoilState(currentDateState);
  const yearMonthPopup = useRecoilValue(yearMonthPopupState);
  const todayYear = new Date().getFullYear();
  const todayMonth = new Date().getMonth() + 1;
  const todayDate = new Date().getDate();
  const audio = useRecoilValue(audioState);

  // const setAudio = useSetRecoilState(audioState);

  const handleToWrite = (clickedDate: Date) => {
    if (clickedDate > new Date()) {
      alert("미래의 일기는 작성할 수 없습니다.");
    } else navigate("/write", { state: { clickedDate } });
  };
  useEffect(() => {
    setCurrentDate(new Date());
  }, []);
  const renderDaysHeader = () => {
    return dayString.map((day) => (
      <div
        key={day}
        style={{ width: "32px", margin: "10px", textAlign: "center" }}
      >
        {day}
      </div>
    ));
  };
  function isToday(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const nowDate = date.getDate();
    if (todayYear === year && todayMonth === month && todayDate === nowDate)
      return true;
    return false;
  }
  const handleClickActive = (id: number | undefined) => {
    navigate(`/diary/${id}`);
  };
  function getDayImg(date: Date) {
    const createClickHandler = (clickDate: Date) => () =>
      handleToWrite(clickDate);
    const handleClick = createClickHandler(new Date(date));

    const id = diaries[date.getDate() - 1]?.id;
    const imgUrl = diaries[date.getDate() - 1]?.imageUrl;
    if (imgUrl)
      return <ImgBox src={imgUrl} onClick={() => handleClickActive(id)} />;
    if (isToday(date)) return <TodayBox onClick={handleClick}>+</TodayBox>;
    return <ImgBox src={tmpImg} onClick={handleClick} />;
  }
  function getDayText(date: Date) {
    if (isToday(date)) return <TodayText>{date.getDate()}</TodayText>;
    return <p>{date.getDate()}</p>;
  }
  const renderDays = () => {
    const monthStart = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1,
    );
    const monthEnd = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0,
    );
    const startDate = new Date(
      monthStart.setDate(monthStart.getDate() - monthStart.getDay()),
    );
    const endDate = new Date(
      monthEnd.setDate(monthEnd.getDate() + (6 - monthEnd.getDay())),
    );

    const days = [];
    let date = startDate;

    while (date <= endDate) {
      // diaries
      days.push(
        <DateBox key={date.toString()}>
          {date.getMonth() === currentDate.getMonth() ? (
            <>
              {getDayImg(date)}
              {getDayText(date)}
            </>
          ) : (
            ""
          )}
        </DateBox>,
      );
      date = new Date(date.setDate(date.getDate() + 1));
    }
    return days;
  };

  useEffect(() => {
    getMonthDiaries(currentDate)
      .then((res) => {
        console.log(res.data.result);
        setDiaries(res.data.result.diaries);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentDate]);

  useEffect(() => {
    // postPush(String(localStorage.getItem("fcmToken")))
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    if (audio) {
      console.log(audio);
      audio.pause();
      console.log("Audio paused");
    } else {
      console.log("No audio to pause");
    }
  }, [audio]);

  return (
    <Flex style={{ flexDirection: "column", alignItems: "center" }}>
      <div>
        <Logo>moo:Doodle</Logo>
        <YearMonthDropdown />
        {yearMonthPopup && <SelectYearMonthPopup />}
        <CalendarHeader>{renderDaysHeader()}</CalendarHeader>
        <CalendarBox>{renderDays()}</CalendarBox>
      </div>
    </Flex>
  );
}

export default Calendar;
