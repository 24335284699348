import { useRecoilState } from "recoil";
import { useState } from "react";
import {
  Dropdown,
  DropdownList,
  DropdownOption,
  DropdownSelected,
} from "../../pages/user/SignupAlarm";
import { periodState } from "../../recoil/atoms";

function PeriodDropdown() {
  const [period, setPeriod] = useRecoilState<string>(periodState);
  const [periodOpen, setPeriodOpen] = useState<boolean>(false);
  return (
    <Dropdown onClick={() => setPeriodOpen(!periodOpen)}>
      <DropdownSelected>{period === "AM" ? "오전" : "오후"}</DropdownSelected>
      <DropdownList isActive={periodOpen}>
        <DropdownOption
          onClick={() => {
            setPeriod("AM");
            setPeriodOpen(false);
          }}
        >
          오전
        </DropdownOption>
        <DropdownOption
          onClick={() => {
            setPeriod("PM");
            setPeriodOpen(false);
          }}
        >
          오후
        </DropdownOption>
      </DropdownList>
    </Dropdown>
  );
}
export default PeriodDropdown;
