import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Landing from "./pages/landing/Landing";
import Login from "./pages/user/Login";
import Signup from "./pages/user/Signup";
import SignupAlarm from "./pages/user/SignupAlarm";

import Home from "./Home";
import Calendar from "./pages/home/Calendar";
import Scrap from "./pages/home/ScrapCategoryList";
import Mypage from "./pages/home/Mypage";
import Report from "./pages/home/Report";
import WriteDiary from "./pages/diary/WriteDiary";
import SignupAgree from "./pages/user/SignupAgree";
import WriteSelectPic from "./pages/diary/WriteSelectPic";
import ViewDiary from "./pages/diary/ViewDiary";
import PatchDiary from "./pages/diary/PatchDiary";
import ScrapDiaryList from "./pages/home/ScrapDiaryList";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup/agree",
        element: <SignupAgree />,
      },
      {
        path: "/signup/info",
        element: <Signup />,
      },
      {
        path: "/signup/alarm",
        element: <SignupAlarm />,
      },
      {
        path: "/write",
        element: <WriteDiary />,
      },
      {
        path: "/write/select",
        element: <WriteSelectPic />,
      },
      {
        path: "/patch/:id",
        element: <PatchDiary />,
      },
      {
        path: "/diary/:id",
        element: <ViewDiary />,
      },
    ],
  },
  {
    path: "/",
    element: <Home />,
    children: [
      { path: "/calendar", element: <Calendar /> },
      { path: "/report", element: <Report /> },
      { path: "/scrap", element: <Scrap /> },
      { path: "/mypage", element: <Mypage /> },
      {
        path: "/scrap/:id",
        element: <ScrapDiaryList />,
      },
    ],
  },
]);

export default router;
