import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const LoginBtn = styled.button`
  width: 320px;
  height: 52px;
  background-color: #fcd3d3;
  border-radius: 15px;
  font-size: 16px;
  color: #d95151;
`;
const SignupBtn = styled(LoginBtn)`
  background-color: #fff;
  color: #111111;
  border: 1px solid #e5e5ec;
`;
const BtnContainer = styled.div`
  bottom: 60px;
  width: 320px;
  margin: 0 auto;
  position: absolute;
`;
function Btn() {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/login");
  };
  const handleSignUp = () => {
    navigate("/signup/agree");
  };
  return (
    <BtnContainer>
      <LoginBtn onClick={handleLogin}>로그인</LoginBtn>
      <SignupBtn onClick={handleSignUp} style={{ marginTop: "12px" }}>
        회원가입
      </SignupBtn>
    </BtnContainer>
  );
}

export default Btn;
