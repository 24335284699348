/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-self-import */
/* eslint-disable import/extensions */

import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import "./firebase-messaging-sw.js";

function FCM() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging(app);

  async function requestPermission() {
    console.log("권한 요청 중...");

    const permission = await Notification.requestPermission();
    if (permission === "denied") {
      console.log("알림 권한 허용 안됨");
      return;
    }

    console.log("알림 권한이 허용됨");

    try {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });
      console.log(token);
      if (token) {
        console.log("token: ", token);
        localStorage.setItem("fcmToken", token);
      } else {
        console.log("Can not get Token");
      }
    } catch (error) {
      console.error("Error getting token: ", error);
    }

    onMessage(messaging, (payload) => {
      console.log("메시지가 도착했습니다.", payload);
      // ...
    });
  }

  requestPermission();
}

export default FCM;
