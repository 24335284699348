import styled from "styled-components";
import { Dispatch, SetStateAction } from "react";
import reportImg from "../../assets/report/reportImg.png";
import { WriteBtn } from "../write/DirayTextArea";

const ReportContainer = styled.div`
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    color: #3e3d3d;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px; /* 130% */
    text-align: center;
    margin-top: 28px;
  }
`;
function ArrivedReport({
  setIsRead,
}: {
  setIsRead: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <ReportContainer>
      <img src={reportImg} alt="리포트 이미지" />
      <p>
        6월 첫째 주<br />
        무드 리포트가 도착했어요!
      </p>
      <WriteBtn
        onClick={() => {
          setIsRead(true);
        }}
      >
        주간 리포트 확인하기
      </WriteBtn>
    </ReportContainer>
  );
}
export default ArrivedReport;
