import Lottie from "react-lottie-player";
import styled from "styled-components";
import drawingJson from "../../assets/diary/drawingGif.json";

export const GifContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 240px;
  p {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px; /* 118.182% */
    letter-spacing: -0.55px;
    margin-bottom: 20px;
  }
  span {
    margin-top: 30px;
    color: #636363;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px; /* 176.923% */
    letter-spacing: -0.325px;
  }
`;
function LoadingPic() {
  return (
    <GifContainer>
      <p>오늘 하루를 그림으로 그려서</p>
      <p>짱돌님께 배달 중이에요!</p>
      <Lottie loop animationData={drawingJson} play />
    </GifContainer>
  );
}
export default LoadingPic;
