import { useRecoilState } from "recoil";
import { useState } from "react";
import {
  Dropdown,
  DropdownList,
  DropdownOption,
  DropdownSelected,
} from "../../pages/user/SignupAlarm";
import { hourState } from "../../recoil/atoms";

function HourDropdown() {
  const [hour, setHour] = useRecoilState<string>(hourState);
  const [hourOpen, setHourOpen] = useState<boolean>(false);
  return (
    <Dropdown onClick={() => setHourOpen(!hourOpen)}>
      <DropdownSelected>{`${hour}시`}</DropdownSelected>
      <DropdownList isActive={hourOpen}>
        {Array.from({ length: 12 }, (_, i) => (
          <DropdownOption
            key={i}
            onClick={() => {
              setHour(String(i + 1).padStart(2, "0"));
              setHourOpen(false);
            }}
          >
            {i + 1}시
          </DropdownOption>
        ))}
      </DropdownList>
    </Dropdown>
  );
}
export default HourDropdown;
