import { useEffect, useState } from "react";
import styled from "styled-components";

const NumOfCharBox = styled.div`
  margin-top: 8px;
  margin-left: auto;
`;
function NumOfChar({ content }: { content: string }) {
  const [inputCount, setInputCount] = useState(content.length);
  useEffect(() => {
    setInputCount(content.length);
  }, [content]);
  return (
    <NumOfCharBox>
      <span>{inputCount}</span>/<span>500</span>
    </NumOfCharBox>
  );
}
export default NumOfChar;
