import { Dispatch, SetStateAction, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { PopupBox, PopupContainer } from "./EditNicknamePopup";
import { Overlay } from "../diary/HamburgerPopup";
import PeriodBtn from "../signup/PeriodDropdown";
import {
  hourState,
  minuteState,
  periodState,
  userInfoState,
} from "../../recoil/atoms";
import HourDropdown from "../signup/HourDropdown";
import MinuteDropdown from "../signup/MinuteDropdown";
import { Flex } from "../../styles/landing";
import { LoginBtn } from "../Landing/Btn";
import getAlarmTime from "../../utils/getAlarmTime";
import { patchAlarmTime } from "../../apis/user-api";

const EditBtn = styled(LoginBtn)`
  width: 100%;
  height: 40px;
  margin-top: 20px;
`;

function EditAlarmPopup({
  setIsEdit,
}: {
  setIsEdit: Dispatch<SetStateAction<boolean>>;
}) {
  const [basicInfo, setBasicInfo] = useRecoilState(userInfoState);
  const [period, setPeriod] = useRecoilState(periodState);
  const [hour, setHour] = useRecoilState(hourState);
  const minute = useRecoilValue(minuteState);

  useEffect(() => {
    if (Number(hour) > 12) {
      setHour(String(Number(hour) - 12));
      setPeriod("PM");
    }
  });

  const handleAlarm = () => {
    const notificationTime = getAlarmTime(period, hour, minute);
    patchAlarmTime(notificationTime).then(() => {
      setIsEdit(false);
      setBasicInfo({ ...basicInfo, notificationTime });
    });
  };
  return (
    <PopupContainer>
      <Overlay
        onClick={() => {
          setIsEdit(false);
        }}
      />
      <PopupBox>
        <h1>알림시간 수정</h1>
        <Flex style={{ marginTop: "16px" }}>
          <PeriodBtn />
          <HourDropdown />
          <MinuteDropdown />
        </Flex>
        <EditBtn onClick={handleAlarm}>수정하기</EditBtn>
      </PopupBox>
    </PopupContainer>
  );
}
export default EditAlarmPopup;
