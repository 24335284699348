/* eslint-disable import/extensions */

import styled from "styled-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layouts/Header";
import { LoginBtn } from "../../components/Landing/Btn";
import kakao from "../../assets/user/kakao.svg";
import naver from "../../assets/user/naver.svg";
import { loginApi, patchPush } from "../../apis/user-api";
import FCM from "../../firebase-messaging-sw";

const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 48px;
`;
const InputWrapper = styled.div`
  display: flex;

  width: 295px;
  height: 48px;

  border-radius: 8px;
  border: 1px solid var(--Line-Regular_Color, #e5e5ec);
  background: var(--Font-01_White, #fff);
`;
const Input = styled.input`
  margin-left: 10px;
  width: 270px;
`;
const SignUp = styled.div`
  margin-top: 12px;

  align-self: end;
  color: #737373;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
`;
const Btn = styled(LoginBtn)`
  border-radius: 8px;
  background: #ffd7cf;
  width: 295px;
  height: 48px;
  flex-shrink: 0;
`;
const SsoBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 295px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;

  color: #3b1e1e;
  font-size: 16px;
  font-weight: 500;

  background: #fbe300;
`;
function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };
  const handleBtn = () => {
    loginApi({ email, password })
      .then((res) => {
        localStorage.setItem("token", res.data.result.accessToken);
        navigate("/calendar");
        FCM();
        patchPush(res.data.result.accessToken);
      })
      .catch(() => {});
  };

  return (
    <>
      <Header text="로그인" />
      <LoginContainer>
        <InputWrapper style={{ marginTop: "70px" }}>
          <Input onChange={handleEmail} placeholder="이메일" />
        </InputWrapper>
        <InputWrapper style={{ marginTop: "12px" }}>
          <Input
            onChange={handlePassword}
            type="password"
            placeholder="비밀번호"
          />
        </InputWrapper>
        <Btn onClick={handleBtn} style={{ marginTop: "24px", width: "295px" }}>
          로그인
        </Btn>
        <SignUp onClick={() => navigate("/signup/agree")}>
          회원가입 하러 가기
        </SignUp>
        <SsoBtn style={{ marginTop: "118px" }}>
          <img src={kakao} alt="" />{" "}
          <p style={{ marginLeft: "8px" }}>카카오 로그인</p>
        </SsoBtn>
        <SsoBtn
          style={{ marginTop: "12px", color: "#fff", background: "#00BF18" }}
        >
          <img src={naver} alt="" />{" "}
          <p style={{ marginLeft: "8px" }}>네이버 로그인</p>
        </SsoBtn>
      </LoginContainer>
    </>
  );
}

export default Login;
