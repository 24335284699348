import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { getDiary } from "../../apis/calandar-api";
import Header from "../../components/layouts/Header";

import HamburgerPopup from "../../components/diary/HamburgerPopup";
import hamburgerBar from "../../assets/diary/hamburgerBar.svg";
import { Flex } from "../../styles/landing";
import offScrapImg from "../../assets/diary/offScrapImg.svg";
import onScrapImg from "../../assets/diary/onScrapImg.svg";
import shareImg from "../../assets/diary/share.svg";
import { postScrap } from "../../apis/scrap-api";
import { audioState } from "../../recoil/atoms";
import playImg from "../../assets/diary/play.svg";
import pauseImg from "../../assets/diary/pause.svg";

interface IEmotion {
  name: string;
  imageUrl: string;
}

export interface Idiary {
  color: string;
  content: string;
  date: string;
  id: number;
  imageUrl: string;
  emotions: IEmotion[];
  isScrapped: boolean;
}
const Container = styled.div``;
const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImgBox = styled.div<{ imageUrl: string; isMood: boolean }>`
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.015);
    }
    100% {
      transform: scale(1);
    }
  }
  margin-top: 34px;
  width: 300px;
  height: 300px;
  border-radius: 8px;
  background: ${(props) => (props.isMood ? "rgba(0, 0, 0, 0.7)" : "#ffe8e4")};
  box-shadow: 0px 10px 28px -2px rgba(35, 48, 59, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: relative;
  animation: ${(props) => (props.isMood ? "none" : "pulse 1s infinite")};
`;
const BackgroundImage = styled.div<{ imageUrl: string; isMood: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;

  position: absolute;
  top: 0;
  left: 0;
`;

const TextOverlay = styled.p<{ textColor: string }>`
  z-index: 1010;
  position: relative;
  background-color: none;
  color: ${(props) => props.textColor};
  font-weight: 600;
`;

const DarkOverlay = styled.div<{ isMood: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props) => (props.isMood ? "block" : "none")};
`;

const Line = styled.div`
  margin-top: 56px;
  border: 1px solid var(--Line-Light_Color, #f1f1f5);
  background: #fff;
  width: 100%;
`;
const DiaryText = styled.textarea`
  margin-top: 24px;
  width: 319px;
  height: 240px;
  /* overflow-y: scroll; */
  flex-shrink: 0;
  color: var(--Font-02_black, #111);

  font-size: 15px;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.375px;
  background: #fefcfc;
`;
const Hamburger = styled.img`
  margin-top: 20px;
  z-index: 11;

  height: 28px;
  display: flex;

  align-items: center;
  :last-child {
    margin-right: 0;
  }
`;
const EmotionBox = styled(Flex)`
  width: 300px;
  height: 300px;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;
const EmotionImg = styled.img`
  z-index: 1010;
  position: relative;
  height: 50px;
  width: 50px;
`;

const EmotionFlex = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;
const IconBox = styled(Flex)`
  width: 300px;
  justify-content: end;
  gap: 16px;
  margin-top: 12px;
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
function ViewDiary() {
  const param = useParams();
  const diaryId = Number(param.id);
  const [popup, setPopup] = useState(false);
  const [audio, setAudio] = useRecoilState(audioState);
  const [diaryInfo, setDiaryInfo] = useState<Idiary>({
    color: "",
    content: "",
    date: "",
    id: diaryId,
    imageUrl: "",
    emotions: [],
    isScrapped: false,
  });
  const [isMood, setIsMood] = useState(false);
  const [isScrap, setIsScrap] = useState(false);
  const [isPlay, setIsPlay] = useState(false);

  useEffect(() => {
    getDiary(diaryId)
      .then((res) => {
        setDiaryInfo(res.data.result);
        setAudio(new Audio(res.data.result.audioUrl));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [diaryId]);

  useEffect(() => {
    if (diaryInfo.isScrapped) setIsScrap(true);
  }, [diaryInfo]);

  const lenderDate = () => {
    if (!diaryInfo?.date) return ""; // date 값이 없는 경우 빈 문자열 반환
    const dateParts = diaryInfo.date.split("-");
    return `${dateParts[0]}년 ${dateParts[1]}월 ${dateParts[2].split("T")[0]}일`;
  };
  const handleMood = () => {
    setIsMood(!isMood);
  };

  const handlePlay = () => {
    setIsPlay(!isPlay);
    if (!isPlay) audio.play();
    else audio.pause();
  };

  const handleScrap = () => {
    if (
      isScrap === true &&
      window.confirm(
        "스크랩 삭제 시 일기가 모든 카테고리에서 삭제됩니다. 삭제하시겠습니까?",
      )
    ) {
      postScrap(diaryId)
        .then(() => {
          setIsScrap(!isScrap);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (isScrap === false)
      postScrap(diaryId)
        .then(() => {
          setIsScrap(!isScrap);
        })
        .catch((err) => {
          console.log(err);
        });
  };
  const handleShare = () => {
    const shareData = {
      title: "Moodoodle",
      text: "무두들 공유하기",
      url: window.location.href,
    };
    try {
      navigator.share(shareData);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Container>
      {popup && <HamburgerPopup diaryInfo={diaryInfo} setPopup={setPopup} />}
      <ViewContainer>
        <Flex
          style={{
            alignItems: "center",
            width: "340px",
            justifyContent: "space-between",
            marginLeft: "-30px",
            marginTop: "20px",
          }}
        >
          <Header text={lenderDate()} />

          <Hamburger
            src={hamburgerBar}
            alt=""
            onClick={() => {
              setPopup(true);
            }}
          />
        </Flex>
        <ImgBox
          imageUrl={diaryInfo.imageUrl}
          isMood={isMood}
          onClick={handleMood}
        >
          <BackgroundImage imageUrl={diaryInfo.imageUrl} isMood={isMood} />
          <DarkOverlay isMood={isMood} />
          {isMood && (
            <Flex
              style={{
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextOverlay
                style={{ position: "absolute", paddingTop: "60px" }}
                textColor="white"
              >
                오늘 하루 감정 변화
              </TextOverlay>
              <EmotionBox>
                {diaryInfo?.emotions.map((e) => {
                  return (
                    <EmotionFlex key={e.name}>
                      <EmotionImg src={e.imageUrl} alt={e.name} />
                      <TextOverlay textColor="white">{e.name}</TextOverlay>
                    </EmotionFlex>
                  );
                })}
              </EmotionBox>
            </Flex>
          )}
        </ImgBox>
        <IconBox>
          {isPlay ? (
            <Icon src={pauseImg} onClick={handlePlay} />
          ) : (
            <Icon src={playImg} onClick={handlePlay} />
          )}
          <Icon onClick={handleShare} src={shareImg} alt="share" />
          {isScrap ? (
            <Icon onClick={handleScrap} src={onScrapImg} alt="scrap on" />
          ) : (
            <Icon onClick={handleScrap} src={offScrapImg} alt="scrap off" />
          )}
        </IconBox>
        <Line />
        <DiaryText readOnly value={diaryInfo?.content} />
      </ViewContainer>
    </Container>
  );
}
export default ViewDiary;
