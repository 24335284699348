export const optionAgree = ` 무두들 및 제휴 서비스의 이벤트・혜택 등의 정보 발송을 위해 무두들
아이디(아이디 식별값 포함), 이메일주소를 수집합니다. 무두들
아이디(아이디 식별값 포함),이메일주소는 무두들 서비스 제공을 위한
필수 수집항목으로서 무두들 회원 가입 기간 동안 보관하나, 이벤트 혜택
정보 수신 동의를 철회하시면 본 목적으로의 개인정보 처리는
중지됩니다. 정보주체는 개인정보 수집 및 이용 동의를 거부하실 수
있으며, 미동의 시에도 서비스 이용은 가능합니다. ※ 일부 서비스(별개의
회원체계 운영, 무두들 가입 후 추가 가입하는 서비스 등)의 경우,
수신에 대해 별도로 안내 드리며, 동의를 구합니다.`;

export const essentialAgree = `여러분을 환영합니다. 무두들 서비스 및 제품(이하 ‘서비스’)을 이용해
주셔서 감사합니다. 본 약관은 다양한 무두들 서비스의 이용과 관련하여
무두들 서비스를 제공하는 무두들 주식회사(이하 ‘무두들’)와 이를
이용하는 무두들 서비스 회원(이하 ‘회원’) 또는 비회원과의 관계를
설명하며, 아울러 여러분의 무두들 서비스 이용에 도움이 될 수 있는
유익한 정보를 포함하고 있습니다. 무두들 서비스를 이용하시거나 무두들
서비스 회원으로 가입하실 경우 여러분은 본 약관 및 관련 운영 정책을
확인하거나 동의하게 되므로, 잠시 시간을 내시어 주의 깊게 살펴봐
주시기 바랍니다. 다양한 무두들 서비스를 즐겨보세요. 무두들은
www.moodoodle.site를 비롯한 무두들 도메인의 웹사이트 및
응용프로그램(어플리케이션, 앱)을 통해 정보 검색, 다른 이용자와의
커뮤니케이션, 콘텐츠 제공, 상품 쇼핑 등 여러분의 생활에 편리함을
더할 수 있는 다양한 서비스를 제공하고 있습니다. 실명 인증된 아이디로 가입, 위치기반서비스 이용약관(선택), 이벤트 •
혜택 정보 수신(선택) 동의를 포함합니다.`;
