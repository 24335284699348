import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useRecoilValue } from "recoil";
import { IscrapCategory } from "../../pages/home/ScrapCategoryList";
import { isEditState } from "../../recoil/atoms";
import { Flex } from "../../styles/landing";
import { deleteScrapCategory, getScrapCategories } from "../../apis/scrap-api";

const CategoryContainer = styled.div`
  width: 152px;
  height: 180px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  h3 {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
  }
  p {
    color: #929292;
    font-size: 12px;
    font-weight: 400;
  }
`;

const ImgBox = styled.img`
  width: 152px;
  height: 152px;

  border-radius: 8px;
  background: url(<path-to-image>) lightgray 50% / cover no-repeat;
`;

const Delete = styled.div`
  margin-left: auto;
  color: red;
`;

function ScrapCategoryBox({
  scrapCategory,
  setScrapCategory,
}: {
  scrapCategory: IscrapCategory;
  setScrapCategory: any;
}) {
  const navigate = useNavigate();
  const isEdit = useRecoilValue(isEditState);
  const handleDiaryList = () => {
    navigate(`/scrap/${scrapCategory.categoryId}`, {
      state: { name: scrapCategory.name },
    });
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    if (window.confirm(`${scrapCategory.name} 삭제하시겠습니까?`)) {
      deleteScrapCategory(scrapCategory.categoryId).then(() => {
        alert(`${scrapCategory.name} 삭제가 완료되었습니다.`);
        getScrapCategories()
          .then((res) => {
            setScrapCategory(res.data.result.categories);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };
  return (
    <CategoryContainer onClick={handleDiaryList}>
      {isEdit && <Delete onClick={handleDelete}>X</Delete>}
      <ImgBox src={scrapCategory.imageUrl} />
      <Flex style={{ justifyContent: "space-between" }}>
        <h3>{scrapCategory.name}</h3>
        <p>{scrapCategory.scrapCount}</p>
      </Flex>
    </CategoryContainer>
  );
}

export default ScrapCategoryBox;
