import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import {
  DateBtn,
  PopupBackground,
  SelctPopupBox,
} from "../calendar/SelectYearMonthPoup";
import { currentDateState, datePopupState } from "../../recoil/atoms";

const DateSelctPopupBox = styled(SelctPopupBox)`
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
`;
function SelectDatePopup() {
  const currentDate = useRecoilValue(currentDateState);
  const setDatePopup = useSetRecoilState(datePopupState);
  const setCurrentDate = useSetRecoilState(currentDateState);
  const monthEnd = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  );
  const handleCurrentDate = (i: number) => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), i),
    );
    setDatePopup(false);
  };
  const renderDateBox = () => {
    const dateBoxes = [];
    for (let i = 1; i <= monthEnd.getDate(); i += 1) {
      dateBoxes.push(
        <DateBtn onClick={() => handleCurrentDate(i)} key={i}>
          {i}일
        </DateBtn>,
      );
    }
    return dateBoxes;
  };
  return (
    <>
      <PopupBackground
        onClick={() => {
          setDatePopup(false);
        }}
      />
      <DateSelctPopupBox
        onClick={() => {
          setDatePopup(false);
        }}
      >
        {renderDateBox()}
      </DateSelctPopupBox>
    </>
  );
}
export default SelectDatePopup;
