import styled from "styled-components";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ScrapDiaryHeader from "../../components/scrap/ScrapDiaryHeader";
import DiaryListBox from "../../components/scrap/DiaryListBox";
import { getScrapDiaries } from "../../apis/scrap-api";
import { IDiaryInfo } from "../../apis/calandar-api";

const DiaryGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  width: 100%;
`;

export const DiaryListContainer = styled.div`
  width: 100%;
  margin-bottom: 60px;
`;

const ImgBox = styled.img`
  width: 100%;
`;

function ScrapDiaryList() {
  const navigate = useNavigate();

  const param = useParams();
  const [isGrid, setIsGrid] = useState(true);
  const [diaries, setDiaries] = useState<IDiaryInfo[]>([]);

  const handleViewDiary = (id: number) => {
    navigate(`/diary/${id}`);
  };

  useEffect(() => {
    getScrapDiaries(Number(param.id)).then((res) => {
      console.log(res);
      setDiaries(res.data.result.diaries);
    });
  }, []);

  return (
    <>
      <ScrapDiaryHeader setIsGrid={setIsGrid} />
      {isGrid ? (
        <DiaryGridContainer>
          {diaries.map((e: IDiaryInfo) => (
            <ImgBox
              onClick={() => handleViewDiary(e.id || 0)}
              src={e.imageUrl}
            />
          ))}
        </DiaryGridContainer>
      ) : (
        <DiaryListContainer>
          {diaries.map((e: IDiaryInfo) => (
            <DiaryListBox info={e} />
          ))}
        </DiaryListContainer>
      )}
    </>
  );
}

export default ScrapDiaryList;
