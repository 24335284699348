import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { IreportInfo } from "../../pages/home/Report";
import { Flex } from "../../styles/landing";
import { reportIdState } from "../../recoil/atoms";

const ReportHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 80px;

  color: #3e3d3d;
  font-size: 20px;
  font-weight: 600;
`;
const Direction = styled.p`
  font-size: 24px;
  font-weight: 400;
`;
function ReportHeader({ reportInfo }: { reportInfo: IreportInfo }) {
  const setReportId = useSetRecoilState(reportIdState);

  const handlePrevReportId = () => {
    if (reportInfo.prevReportId < 0) alert("이전 리포트가 존재하지 않습니다!");
    setReportId(reportInfo.prevReportId);
  };

  const handleNextReportId = () => {
    if (reportInfo.nextReportId < 0) alert("다음 리포트가 존재하지 않습니다!");
    setReportId(reportInfo.nextReportId);
  };
  return (
    <ReportHeaderContainer>
      <Direction onClick={handlePrevReportId}>{`${"<"}`}</Direction>
      {reportInfo.reportWeek}
      <Direction onClick={handleNextReportId}>{`${">"}`}</Direction>
    </ReportHeaderContainer>
  );
}
export default ReportHeader;
