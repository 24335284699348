import { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { Overlay } from "../diary/HamburgerPopup";
import { Flex } from "../../styles/landing";
import { userInfoState } from "../../recoil/atoms";
import { IbasicInfo } from "../../pages/home/Mypage";
import { LoginBtn } from "../Landing/Btn";
import { patchUserNickname } from "../../apis/user-api";

export const PopupContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
`;

export const PopupBox = styled(Flex)`
  flex-direction: column;
  position: absolute;

  background-color: #fff;
  z-index: 1501;

  border-radius: 12px;
  padding: 20px;

  input {
    width: 200px;
    height: 40px;
    padding-left: 12px;
  }
`;
const EditBtn = styled(LoginBtn)`
  width: 60px;
  height: 40px;

  font-size: 14px;
  margin-left: 12px;
`;
const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #111;
`;

function EditNicknamePopup({
  setIsEdit,
}: {
  setIsEdit: Dispatch<SetStateAction<boolean>>;
}) {
  const [basicInfo, setBasicInfo] = useRecoilState<IbasicInfo>(userInfoState);
  const [nickname, setNickname] = useState("");
  const handleNickname = (e: any) => {
    setNickname(e.target.value);
  };

  const handlePatchNickname = () => {
    patchUserNickname(nickname).then(() => {
      setIsEdit(false);
      setBasicInfo({ ...basicInfo, nickname });
    });
  };
  return (
    <PopupContainer>
      <Overlay
        onClick={() => {
          setIsEdit(false);
        }}
      />

      <PopupBox>
        <h1>닉네임 변경</h1>

        <Flex style={{ marginTop: "12px" }}>
          <div>
            <input
              onChange={handleNickname}
              defaultValue={basicInfo.nickname}
              placeholder="닉네임"
            />
            <Line />
          </div>

          <EditBtn onClick={handlePatchNickname}>수정 완료</EditBtn>
        </Flex>
      </PopupBox>
    </PopupContainer>
  );
}
export default EditNicknamePopup;
