import styled from "styled-components";
import Logo from "../../assets/landing/Rectangle.png";
import Btn from "../../components/Landing/Btn";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin-top: 40px;
    color: #0c0c0c;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
  }
  h2 {
    margin-top: 4px;
    color: #a5a5a5;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
  }
`;

const MainLogo = styled.img`
  margin-top: 120px;
  width: 240px;
  height: 160px;
  @keyframes float {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-30px);
    }
  }
  animation: float 2s ease-in-out infinite;
  opacity: 1;
`;

function Landing() {
  return (
    <Container>
      <MainLogo src={Logo} alt="" />
      <h1>무두들에 오신 것을 환영해요!</h1>
      <h2 style={{ marginTop: "40px" }}>
        이곳에서는 여러분의 소중한 순간들이 예술 작품으로 탄생해요.
      </h2>
      <h2> 자, 함께 캔버스를 채워나갈 준비가 되셨나요?</h2>
      <Btn />
    </Container>
  );
}

export default Landing;
