import styled from "styled-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import NumOfChar from "../../utils/\bNumOfChar";
import { postDiaryImages } from "../../apis/calandar-api";
import { currentDateState } from "../../recoil/atoms";

export const WriteBox = styled.div`
  display: flex;

  margin: 20px auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
`;
export const WriteBtn = styled.button`
  margin-top: 40px;
  border-radius: 8px;
  background: #fc8871;
  width: 280px;
  height: 52px;
  flex-shrink: 0;
  color: #fff;
`;
export const WriteArea = styled.textarea`
  width: 280px;
  height: 400px;
  background: #fefcfc;

  flex-shrink: 0;

  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--Line-Regular_Color, #e5e5ec);
`;
function DiaryTextArea({ setLoading }: { setLoading: any }) {
  const currentDate = useRecoilValue(currentDateState);
  const navigate = useNavigate();
  const [diaryContent, setDiaryContent] = useState("");

  const handleInput = (e: any) => {
    setDiaryContent(e.target.value);
  };
  const handleGeneratePic = () => {
    let month = `${currentDate.getMonth() + 1}`;
    let date = `${currentDate.getDate()}`;
    if (month.length < 2) month = `0${month}`;
    if (date.length < 2) date = `0${date}`;

    setLoading(true);
    setTimeout(() => {
      postDiaryImages(diaryContent)
        .then((res) => {
          setLoading(false);
          navigate("/write/select", {
            state: {
              imageUrls: res.data.result.imageUrls,
              diaryContent,
              date: `${currentDate.getFullYear()}-${month}-${date}`,
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);
  };
  return (
    <WriteBox>
      <WriteArea
        onChange={handleInput}
        maxLength={500}
        placeholder={`오늘 하루는 어땠나요?

감정이 드러나게 글을 작성해주세요!`}
      />

      <NumOfChar content={diaryContent} />

      <WriteBtn onClick={handleGeneratePic}>그림 그리기</WriteBtn>
    </WriteBox>
  );
}

export default DiaryTextArea;
