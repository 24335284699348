import styled from "styled-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  calendar,
  report,
  pen,
  scrap,
  mypage,
  onCalendar,
  onReport,
  onScrap,
  onMypage,
} from "../../assets/footerImg";

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding-bottom: 8px;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 80px;
  border-top: 1px solid var(--Line-Light_Color, #f1f1f5);
  background: #fff;
  white-space: nowrap;
  z-index: 1040;
`;
const ContentImg = styled.img`
  width: 24px;
  height: 24px;
  pointer-events: none;
`;
const PenBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #fc8871;
`;

const TabData = [
  { id: "calendar", text: "일기", defaultImg: calendar, activeImg: onCalendar },
  { id: "report", text: "주간무드", defaultImg: report, activeImg: onReport },
  { id: "pen" },
  { id: "scrap", text: "스크랩", defaultImg: scrap, activeImg: onScrap },
  { id: "mypage", text: "마이페이지", defaultImg: mypage, activeImg: onMypage },
];

const ContentBox = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;

  p {
    pointer-events: none;
    margin-top: 4px;
    color: ${(props) => (props.active ? "#FC8871" : "#8c95a0")};
    text-align: center;
    font-size: 10px;
    font-weight: 700;
  }
`;

function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState("calendar");

  const handleTab = (id: string) => {
    setActive(id);
    const clickedDate = new Date();
    if (id === "write") navigate(`${id}`, { state: { clickedDate } });
    else navigate(`${id}`);
  };

  useEffect(() => {
    setActive(location.pathname.split("/")[1]);
  }, []);

  return (
    <FooterContainer>
      {TabData.map((tab) =>
        tab.id === "pen" ? (
          <PenBox
            onClick={() => {
              handleTab("write");
            }}
          >
            <ContentImg src={pen} />
          </PenBox>
        ) : (
          <ContentBox
            key={tab.id}
            active={active === tab.id}
            onClick={() => handleTab(tab.id)}
          >
            <ContentImg
              src={active === tab.id ? tab.activeImg : tab.defaultImg}
            />
            <p>{tab.text}</p>
          </ContentBox>
        ),
      )}
    </FooterContainer>
  );
}

export default Footer;
