import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import Header from "../../components/layouts/Header";
import Progress from "../../components/signup/Progress";
import { BigBtn, SignupContainer } from "./Signup";
import { postSignUp } from "../../apis/user-api";
import PeriodDropdown from "../../components/signup/PeriodDropdown";
import { hourState, minuteState, periodState } from "../../recoil/atoms";
import HourDropdown from "../../components/signup/HourDropdown";
import MinuteDropdown from "../../components/signup/MinuteDropdown";
import getAlarmTime from "../../utils/getAlarmTime";

interface DropdownProps {
  isActive: boolean;
}

export const Dropdown = styled.div`
  position: relative;
  width: 80px;
  text-align: center;

  cursor: pointer;
  margin-right: 10px; /* 각 드롭다운 사이의 간격 */
`;

export const DropdownSelected = styled.div`
  text-align: center;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
`;

export const DropdownList = styled.div<DropdownProps>`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  overflow: scroll;
  max-height: ${(props) =>
    props.isActive ? "200px" : "0"}; /* Adjusted for animation */
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition:
    max-height 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
`;

export const DropdownOption = styled.div`
  padding: 8px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

function SignupAlarm() {
  const location = useLocation();
  const navigate = useNavigate();

  const period = useRecoilValue<string>(periodState);
  const hour = useRecoilValue<string>(hourState);
  const minute = useRecoilValue<string>(minuteState);

  const handleSignup = async () => {
    const notificationTime = getAlarmTime(period, hour, minute);
    console.log(location.state);
    postSignUp(
      { ...location.state.info, notificationTime },
      location.state.emailToken,
    ).then(() => {
      navigate("/");
    });
  };

  return (
    <div>
      <Header text="회원가입" />
      <SignupContainer>
        <h1>알림시간 선택</h1>
        <div
          style={{
            marginTop: "160px",
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Period Dropdown */}
          <PeriodDropdown />
          {/* Hour Dropdown */}
          <HourDropdown />
          {/* Minute Dropdown */}
          <MinuteDropdown />
        </div>
        <BigBtn active onClick={handleSignup}>
          회원가입
        </BigBtn>
      </SignupContainer>
      <Progress page={3} />
    </div>
  );
}

export default SignupAlarm;
