import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API;

// 요청 인터셉터 추가
axios.interceptors.request.use(
  function (config) {
    // 요청이 전달되기 전에 작업 수행
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);
// 응답 인터셉터 추가
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        // status code가 401인 경우 `logout`을 커밋하고 `/login` 페이지로 리다이렉트
        case 409:
          alert(error.response.data.message);
          return new Promise(() => {});

        default:
          return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  },
);

export const emailApi = (checkEmail: string) => {
  return axios.get("/api/auth/check-email", { params: { email: checkEmail } });
};
export const emailSendApi = (checkEmail: string) => {
  return axios.post("/api/auth/send-verification-email", null, {
    params: { email: checkEmail },
  });
};
export const verifyApi = (emailInfo: object) => {
  return axios.post("/api/auth/verify-email", emailInfo);
};

export const loginApi = (info: object) => {
  return axios.post("/api/auth/sign-in", info);
};

export const postSignUp = (info: object, token: string) => {
  return axios.post("api/auth/sign-up", info, {
    headers: { "X-Email-Verification-Token": token },
  });
};

export const getUserInfo = () => {
  return axios.get("/api/users");
};

export const deleteUser = () => {
  return axios.delete("/api/users/withdraw");
};

export const patchUserNickname = (nickname: string) => {
  return axios.patch("/api/users", { nickname });
};

export const patchAlarmTime = (notificationTime: string) => {
  return axios.patch("/api/users/notification-time", { notificationTime });
};

// export const postPush = (token: string) => {
//   return axios.post("api/users/send-test-notification", {
//     fcmToken: token,
//     title: "제목",
//     body: "내용",
//   });
// };

export const patchPush = (token: string) => {
  return axios.patch("/api/users/push-notifications", {
    pushNotificationsEnabled: true,
    fcmToken: token,
  });
};
