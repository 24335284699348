/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import styled from "styled-components";
import { Dispatch, SetStateAction, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { Flex } from "../../styles/landing";
import onGrid from "../../assets/scrap/onGrid.svg";
import offGrid from "../../assets/scrap/offGrid.svg";
import onList from "../../assets/scrap/onList.svg";
import offList from "../../assets/scrap/offList.svg";
import back from "../../assets/layouts/back.svg";

const CategoryContainer = styled(Flex)`
  margin-top: 20px;
  width: 100%;
  p {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 130% */
  }

  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const SubMenuContainer = styled(Flex)`
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
  div {
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 8px;
  }
  img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
`;
const BackBtn = styled.img`
  position: absolute;
  left: 20px;
`;
function ScrapDiaryHeader({
  setIsGrid,
}: {
  setIsGrid: Dispatch<SetStateAction<boolean>>;
}) {
  const [grid, setGrid] = useState(onGrid);
  const [list, setList] = useState(offList);
  const navigate = useNavigate();
  const location = useLocation();

  const categoryName = location.state.name;

  const handleGrid = () => {
    setGrid(onGrid);
    setList(offList);
    setIsGrid(true);
  };
  const handleList = () => {
    setGrid(offGrid);
    setList(onList);
    setIsGrid(false);
  };
  return (
    <CategoryContainer>
      <Flex>
        <BackBtn
          onClick={() => {
            navigate(-1);
          }}
          src={back}
          alt=""
        />
        <p>{categoryName}</p>
      </Flex>
      <SubMenuContainer>
        <div onClick={handleGrid}>
          <img src={grid} alt="" />
        </div>

        <div onClick={handleList}>
          <img src={list} alt="" />
        </div>
      </SubMenuContainer>
    </CategoryContainer>
  );
}
export default ScrapDiaryHeader;
