import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

import { useState } from "react";
import deleteIcon from "../../assets/diary/deleteDiary.svg";
import editIcon from "../../assets/diary/editDiary.svg";
import categoryImg from "../../assets/diary/categoryImg.svg";

import brushImg from "../../assets/diary/brush.svg";

import { deleteDiary } from "../../apis/calandar-api";
import { Idiary } from "../../pages/diary/ViewDiary";
import { Flex } from "../../styles/landing";
import ScrapCategoryPopup, { IconBox } from "./ScrapCategoryPopup";

const TextBox = styled.div`
  width: 319px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #efefef;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;
const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;
`;
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1500;
`;
const BigBox = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 66px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #efefef;
`;
const LongBox = styled(Flex)`
  border-radius: 15px;
  padding: 8px 16px;
  background: #efefef;
  display: flex;
  align-items: center;
`;

function HamburgerPopup({
  diaryInfo,
  setPopup,
}: {
  diaryInfo: Idiary;
  setPopup: any;
}) {
  const param = useParams();
  const navigate = useNavigate();
  const diaryId = Number(param.id);
  const [isCategoryPopup, setIsCategoryPopup] = useState(false);
  const handleReGenerate = () => {
    alert("그림 재생성하기");
  };

  const handleDelete = () => {
    if (window.confirm("일기를 삭제하시겠습니까?"))
      deleteDiary(diaryId)
        .then(() => {
          alert("일기가 삭제되었습니다.");
          navigate("/calendar");
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const handlePatch = () => {
    if (window.confirm("일기를 수정하시겠습니까?"))
      navigate(`/patch/${diaryId}`, { state: { ...diaryInfo } });
  };
  return (
    <>
      <Overlay onClick={() => setPopup(false)} />
      {isCategoryPopup ? (
        <ScrapCategoryPopup diaryId={diaryId} />
      ) : (
        <IconBox>
          <div>
            <Flex style={{ justifyContent: "space-between" }}>
              <BigBox
                onClick={() => {
                  setIsCategoryPopup(true);
                }}
              >
                <img src={categoryImg} alt="" />
                <p>스크랩 카테고리</p>
              </BigBox>
              <BigBox onClick={handleReGenerate}>
                <img src={brushImg} alt="" />
                <p>그림 다시 그리기</p>
              </BigBox>
            </Flex>
            <TextBox>
              <LongBox onClick={handlePatch}>
                <Icon src={editIcon} alt="" />
                <p>수정</p>
              </LongBox>

              <hr />
              <LongBox onClick={handleDelete}>
                <Icon src={deleteIcon} alt="" />
                <p style={{ color: "#FD0034" }}>삭제</p>
              </LongBox>
            </TextBox>
          </div>
        </IconBox>
      )}
    </>
  );
}

export default HamburgerPopup;
