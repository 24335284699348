// import LoadingPic from "../../components/write/LoadingPic";
// import SuccessDiary from "../../components/write/SuccessDiary";
/* <LoadingPic /> */
/* <SuccessDiary /> */

import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import ArrivedReport from "../../components/report/ArrivedReport";
import { getIsRead, getReport } from "../../apis/report-api";
import ReportStatus from "../../components/report/ReportStatus";
import ReportHeader from "../../components/report/ReportHeader";
import { reportIdState } from "../../recoil/atoms";

export interface IreportInfo {
  id: 0;
  reportWeek: "string";
  prevReportId: 0;
  nextReportId: 0;
  asset: {
    url: "string";
    description: "string";
  };
  emotions: [
    {
      name: "string";
      percentage: 0;
    },
  ];
  positivePercentage: 0;
  negativePercentage: 0;
}
function Report() {
  const [isRead, setIsRead] = useState(false);
  const [reportId, setReportId] = useRecoilState(reportIdState);
  const [reportInfo, setReportInfo] = useState<IreportInfo>();
  useEffect(() => {
    getIsRead().then((res) => {
      // setIsRead(res.data.result.isRead);
      setReportId(res.data.result.reportId);
    });
  }, []);

  useEffect(() => {
    if (reportId !== -1)
      getReport(reportId)
        .then((res) => {
          setReportInfo(res.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [reportId]);

  return (
    <div>
      {reportInfo && (
        <>
          <ReportHeader reportInfo={reportInfo} />
          {isRead ? (
            <ReportStatus reportInfo={reportInfo} />
          ) : (
            <ArrivedReport setIsRead={setIsRead} />
            // <ArrivedReport setIsRead={setIsRead} />
          )}
        </>
      )}
    </div>
  );
}
export default Report;
