import styled from "styled-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layouts/Header";
import InputBox from "../../components/signup/Input";
import { Flex } from "../../styles/landing";
import Progress from "../../components/signup/Progress";
import { emailApi, emailSendApi, verifyApi } from "../../apis/user-api";

export const SignupContainer = styled.div`
  width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    align-self: start;
    margin-top: 28px;
    margin-left: -12px;
    color: var(--Font-02_black, #111);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  h2 {
    margin-top: 16px;

    color: var(--Font-02_black, #111);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
`;
const Btn = styled.button`
  margin-top: 8px;
  margin-left: 8px;

  width: 56px;
  height: 44px;
  border-radius: 8px;
  background: #ffd7cf;

  color: #e5664d;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.35px;
`;
export const BigBtn = styled(Btn)<{ active: boolean }>`
  width: 319px;
  height: 52px;
  margin: 0;

  flex-shrink: 0;
  font-size: 16px;
  background-color: ${(props) => (props.active ? "" : "#DFE0E2")};
  pointer-events: ${(props) => (props.active ? "" : "none")};
  color: ${(props) => (props.active ? "" : "#777")};
  position: absolute;
  bottom: 40px;
`;
const Error = styled.div`
  margin-top: 4px;
  color: red;
  font-size: 14px;
`;
const Success = styled(Error)`
  color: blue;
`;
function Signup() {
  const [info, setInfo] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [emailOn, setEmailOn] = useState(false);
  const [emailCode, setEmailCode] = useState(Number);
  const [emailToken, setEmailToken] = useState(String);
  const [passwordCheck, setPasswordCheck] = useState(String);
  const navigate = useNavigate();

  const checkPassword = (password: string) => {
    if (/^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(password)) return true;
    return false;
  };
  const verifyEmail = () => {
    verifyApi({ email: info.email, code: emailCode })
      .then((res: any) => {
        setEmailToken(res.data.result.emailVerificationToken);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEmail = () => {
    if (info.email.includes("@") && info.email.includes(".")) {
      emailApi(info.email)
        .then(() => {
          emailSendApi(info.email).then(() => {
            setEmailOn(true);
            alert("인증번호가 전송되었습니다.");
          });
        })
        .catch((err) => {
          alert(err.response.data.message);
          alert("유효하지 않은 이메일 정보입니다.");
        });
    }
    // 이메일 형식 찾기
    else alert("이메일 형식을 확인해주세요.");
  };
  const effectCheck = () => {
    if (!emailToken) return false;
    if (!Object.values(info).every((x) => x !== null && x !== "")) return false;
    if (passwordCheck !== info.password) return false;

    return true;
  };
  const handleNext = () => {
    if (effectCheck() === true)
      navigate("/signup/alarm", { state: { info, emailToken } });
  };
  return (
    <div>
      <Header text="회원가입" />
      <SignupContainer>
        <h1>회원정보 입력</h1>
        <div>
          <h2 style={{ marginTop: "20px" }}>닉네임</h2>
          <InputBox
            id="name"
            info={info}
            setInfo={setInfo}
            placeholder="닉네임 입력"
          />
        </div>
        <div>
          <h2>이메일</h2>
          <Flex>
            <InputBox
              id="email"
              info={info}
              setInfo={setInfo}
              placeholder="이메일 입력"
              small
            />
            <Btn onClick={handleEmail}>인증번호 받기</Btn>
          </Flex>
          {emailToken ? (
            <Success>인증이 완료되었습니다.</Success>
          ) : (
            <Error>이메일을 통해 인증해주세요.</Error>
          )}
          {emailOn ? (
            <Flex>
              <InputBox
                id="emailCode"
                setInfo={setEmailCode}
                placeholder="인증번호 입력"
                small
              />
              <Btn onClick={verifyEmail}>인증하기</Btn>
            </Flex>
          ) : (
            ""
          )}
        </div>
        <div>
          <h2>비밀번호</h2>
          <InputBox
            type="password"
            id="password"
            info={info}
            setInfo={setInfo}
            placeholder="비밀번호 입력"
          />
          {info.password && !checkPassword(info.password) ? (
            <Error>8자 이상, 영어와 숫자 포함</Error>
          ) : (
            ""
          )}
          <InputBox
            id="passwordCheck"
            setInfo={setPasswordCheck}
            type="password"
            placeholder="비밀번호 확인"
          />
          {passwordCheck && info.password !== passwordCheck ? (
            <Error>비밀번호가 다릅니다</Error>
          ) : (
            ""
          )}
        </div>
        <BigBtn active={effectCheck()} onClick={handleNext}>
          다음
        </BigBtn>
      </SignupContainer>

      <Progress page={2} />
    </div>
  );
}

export default Signup;
