/* eslint-disable no-param-reassign */

import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API;

axios.interceptors.request.use(
  function requestSucc(config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function requestErr() {},
);
axios.interceptors.response.use(
  function responseSucc(res) {
    return res;
  },
  function responseErr(err) {
    if (err.response.data.code === 4054) {
      alert("토큰이 만료되었습니다. 다시 로그인해주세요");
      window.location.href = "/login";
    } else if (err.response.data.code === 4053) {
      alert("로그인이 필요한 서비스입니다.");
      window.location.href = "/login";
    } else alert(err.response.data.message);
  },
);
export const getScrapId = (diaryId: number) => {
  return axios.get(`/api/scraps/${diaryId}`);
};
export const postScrap = (diaryId: number) => {
  return axios.post(`/api/scraps`, null, { params: { diaryId } });
};
export const postScrapToCategory = (scrapId: number, categoryId: number) => {
  return axios.post(`/api/scraps/${scrapId}/categories`, null, {
    params: { categoryId },
  });
};
// 카테고리 API
export const getScrapCategories = () => {
  return axios.get("/api/categories");
};

export const getScrapDiaries = (categoryId: number) => {
  return axios.get(`/api/categories/${categoryId}/diaries`);
};

export const getScarpCategoriesBottom = (diaryId: number) => {
  return axios.get(`/api/categories/scraps`, { params: { diaryId } });
};

export const postScrapCategory = (name: string) => {
  return axios.post(`/api/categories`, { name });
};

export const deleteScrapCategory = (categoryId: number) => {
  return axios.delete(`/api/categories/${categoryId}`);
};
