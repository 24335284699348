import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { currentDateState, yearMonthPopupState } from "../../recoil/atoms";
import dropDown from "../../assets/calendar/dropDown.svg";
import { Flex } from "../../styles/landing";

export const CurrentDateBox = styled(Flex)`
  margin-left: 20px;
  margin-top: 12px;
  color: var(--Font-02_black, #111);
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: -0.4px;
`;
function YearMonthDropdown() {
  const currentDate = useRecoilValue(currentDateState);
  const setDatePopup = useSetRecoilState(yearMonthPopupState);

  const handlePopup = () => {
    console.log("클릭");
    setDatePopup(true);
  };

  return (
    <CurrentDateBox style={{ marginLeft: "20px" }} onClick={handlePopup}>
      {currentDate.getFullYear()}년 {currentDate.getMonth() + 1}월
      <img src={dropDown} alt="드롭다운 이미지" />
    </CurrentDateBox>
  );
}

export default YearMonthDropdown;
