import styled from "styled-components";
import { useEffect, useState } from "react";
import { DiaryListContainer } from "../../pages/home/ScrapDiaryList";
import { getScarpCategoriesBottom } from "../../apis/scrap-api";
import { Flex } from "../../styles/landing";
import plusImg from "../../assets/scrap/plus.svg";
import MakeCategoryPopup from "./MakeCategoryPopup";
import CategoryListBox from "./CategoryListBox";

export const IconBox = styled.div`
  @keyframes swipeUp {
    0% {
      transform: translateY(300px);
    }
    100% {
      transform: translateY();
    }
  }
  animation: swipeUp 0.5s;
  position: fixed;
  z-index: 1501;
  bottom: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  border-radius: 25px 25px 0px 0px;
  background: #fff;
  align-items: center;

  padding: 28px 20px;
  p {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 220% */
    letter-spacing: 0.7px;
  }
  hr {
    height: 1px;
    border: 1px solid;
    color: #dbdbdb;
    border-color: #dbdbdb;
    background-color: #dbdbdb;
  }
`;
const ScarpCategoryContainer = styled(IconBox)`
  height: 600px;
  position: fixed;
  overflow-y: scroll;
`;
const ScarpCategoryHeader = styled(Flex)`
  width: 100%;
  height: 40px;

  align-items: center;
  justify-content: center;
  background: #fff;

  img {
    position: absolute;
    left: 20px;
  }
`;
const Plus = styled.img``;
function ScrapCategoryPopup({ diaryId }: { diaryId: number }) {
  const [categories, setCategories] = useState([]);
  const [postCategoryPopup, setPostCategoryPopup] = useState(false);
  const [isScrap, setIsScrap] = useState(false);
  useEffect(() => {
    if (!postCategoryPopup)
      getScarpCategoriesBottom(diaryId)
        .then((res) => {
          setCategories(res.data.result.categories);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [postCategoryPopup, isScrap]);

  return postCategoryPopup ? (
    <MakeCategoryPopup setPostCategoryPopup={setPostCategoryPopup} />
  ) : (
    <ScarpCategoryContainer>
      <ScarpCategoryHeader>
        <Plus
          onClick={() => {
            setPostCategoryPopup(true);
          }}
          src={plusImg}
          alt=""
        />
        <h1>스크랩 카테고리</h1>
      </ScarpCategoryHeader>
      <DiaryListContainer style={{ marginTop: "16px" }}>
        {categories.length !== 0
          ? categories.map((e) => (
              <CategoryListBox info={e} setIsScrap={setIsScrap} />
            ))
          : "카테고리가 존재하지 않습니다."}
      </DiaryListContainer>
    </ScarpCategoryContainer>
  );
}

export default ScrapCategoryPopup;
