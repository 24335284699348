import styled from "styled-components";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import BasicInfoBox from "../../components/mypage/BasicInfoBox";
import { Flex } from "../../styles/landing";
import { deleteUser, getUserInfo } from "../../apis/user-api";
import { hourState, minuteState, userInfoState } from "../../recoil/atoms";
import AlarmBox from "../../components/mypage/AlarmBox";

const MypageContainer = styled(Flex)`
  padding: 20px;
  flex-direction: column;
  align-items: start;

  min-height: 80vh;

  h2 {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin: 12px 0;
  }
`;
const HeaderContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
`;
export const HeaderText = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #6a6868;
`;
export const EditBox = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  background-color: #fff;
  height: 52px;
`;

export interface IbasicInfo {
  id: number;
  email: string;
  nickname: string;
  notificationTime: string;
  authProvider: string;
}
function Mypage() {
  const navigate = useNavigate();

  const setBasicInfo = useSetRecoilState<IbasicInfo>(userInfoState);
  const setHour = useSetRecoilState(hourState);
  const setMinute = useSetRecoilState(minuteState);

  useEffect(() => {
    getUserInfo().then((res) => {
      setBasicInfo(res.data.result);

      setHour(res.data.result.notificationTime.split(":")[0]);
      setMinute(res.data.result.notificationTime.split(":")[1]);
    });
  }, []);

  const handleLogout = () => {
    localStorage.setItem("token", "");
    navigate("/");
  };

  const handleDeleteUser = () => {
    if (window.confirm("정말 계정을 삭제하시겠습니까?")) {
      deleteUser().then(() => {
        alert("계정 탈퇴가 완료되었습니다.");
        navigate("/");
      });
    }
  };

  return (
    <MypageContainer>
      <HeaderContainer>
        <HeaderText>마이페이지</HeaderText>
      </HeaderContainer>
      <BasicInfoBox />
      <h2>알림</h2>
      <AlarmBox />
      <h2>회원 정보</h2>
      <EditBox onClick={handleLogout}>
        로그아웃 <p>{`${">"}`}</p>
      </EditBox>
      <EditBox onClick={handleDeleteUser}>
        회원 탈퇴 <p>{`${">"}`}</p>
      </EditBox>
    </MypageContainer>
  );
}

export default Mypage;
