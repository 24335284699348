import styled from "styled-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layouts/Header";
import { BigBtn, SignupContainer } from "./Signup";
import checkBox from "../../assets/user/agree.svg";
import onCheckBox from "../../assets/user/onAgree.svg";
import { Flex } from "../../styles/landing";
import { essentialAgree, optionAgree } from "../../assets/user/agree";
import Progress from "../../components/signup/Progress";

const AgreeBox = styled.div`
  margin-top: 20px;

  color: var(--Font1, #333);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  span {
    margin-left: 8px;
  }
  p {
    height: 96px;
    margin-top: 8px;
    color: #555;
    padding: 12px;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    overflow-y: scroll;
  }
`;
function SignupAgree() {
  const navigate = useNavigate();
  const [option, setOption] = useState(false);
  const [essential, setEssential] = useState(false);
  const handleAll = () => {
    if (option && essential) {
      setOption(false);
      setEssential(false);
    } else {
      setOption(true);
      setEssential(true);
    }
  };
  const handleEssential = () => {
    setEssential(!essential);
  };
  const handleOption = () => {
    setOption(!option);
  };
  const handleNext = () => {
    navigate("/signup/info");
  };
  return (
    <>
      <Header text="회원가입" />
      <SignupContainer>
        <h1>약관동의</h1>
        <AgreeBox>
          <Flex onClick={handleAll}>
            {option && essential ? (
              <img src={onCheckBox} alt="" />
            ) : (
              <img src={checkBox} alt="" />
            )}
            <span>약관 전체 동의하기</span>
          </Flex>
        </AgreeBox>
        <AgreeBox>
          <Flex onClick={handleEssential}>
            {essential ? (
              <img src={onCheckBox} alt="" />
            ) : (
              <img src={checkBox} alt="" />
            )}

            <span style={{ color: "#E5664D" }}>[필수]</span>
            <span>무두들 이용약관</span>
          </Flex>
          <p>{essentialAgree}</p>
        </AgreeBox>
        <AgreeBox>
          <Flex onClick={handleOption}>
            {option ? (
              <img src={onCheckBox} alt="" />
            ) : (
              <img src={checkBox} alt="" />
            )}
            <span style={{ color: "#888" }}>[선택]</span>
            <span>이벤트 • 혜택 정보 수신</span>
          </Flex>
          <p>{optionAgree}</p>
        </AgreeBox>
        <BigBtn onClick={handleNext} active={essential}>
          다음
        </BigBtn>
      </SignupContainer>
      <Progress page={1} />
    </>
  );
}

export default SignupAgree;
