import styled from "styled-components";

const ProgressContainer = styled.div`
  position: absolute;
  bottom: 20px;

  width: 100%;
  height: 4px;
  background-color: #ffe8e4;
  display: flex;
`;
const Section = styled.div`
  @keyframes progress {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  animation: progress 2s ease-in-out;
  width: 33.33333%;
  background: #fc8871;
`;
interface IProgress {
  page: number;
}
function Progress({ page }: IProgress) {
  return (
    <ProgressContainer>
      {page >= 1 ? <Section /> : ""}
      {page >= 2 ? <Section /> : ""}
      {page >= 3 ? <Section /> : ""}
    </ProgressContainer>
  );
}

export default Progress;
