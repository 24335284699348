import styled from "styled-components";

interface Iprop {
  small?: boolean;
}
const InputWrapper = styled.div<Iprop>`
  width: ${(props) => (props.small ? "230px" : "294px;")};
  display: flex;
  align-items: center;
  margin-top: 8px;

  height: 44px;

  border-radius: 8px;
  border: 1px solid var(--Line-Regular_Color, #e5e5ec);
  background: var(--Font-01_White, #fff);
`;
const Input = styled.input`
  margin-left: 16px;
  max-width: 200px;
  background: var(--Font-01_White, #fff);
`;
interface IInputBox {
  placeholder: string;
  type?: string;
  small?: boolean;
  setInfo?: any;
  info?: object;
  id?: string;
}

function InputBox({ placeholder, small, setInfo, info, id, type }: IInputBox) {
  const handleInput = (e: any) => {
    if (id === "emailCode" || id === "passwordCheck") setInfo(e.target.value);
    else if (id) setInfo({ ...info, [e.target.id]: e.target.value });
    console.log(info, e);
  };
  return (
    <InputWrapper small={small}>
      <Input
        type={type}
        id={id}
        onChange={handleInput}
        placeholder={placeholder}
      />
    </InputWrapper>
  );
}

export default InputBox;
