/* eslint-disable no-param-reassign */

import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API;

axios.interceptors.request.use(
  function requestSucc(config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function requestErr() {},
);
axios.interceptors.response.use(
  function responseSucc(res) {
    return res;
  },
  function responseErr(err) {
    if (err.response.data.code === 4054) {
      alert("토큰이 만료되었습니다. 다시 로그인해주세요");
      window.location.href = "/login";
    } else if (err.response.data.code === 4053) {
      alert("로그인이 필요한 서비스입니다.");
      window.location.href = "/login";
    } else alert(err.response.data.message);
  },
);

export const getIsRead = () => {
  return axios.get("/api/reports/latest");
};

export const getReport = (reportId: number) => {
  return axios.get(`/api/reports/${reportId}`);
};
