import { Outlet } from "react-router-dom";
import GlobalStyle from "./styles/GlobalStyles";
import Footer from "./components/layouts/Footer";

function Home() {
  return (
    <>
      <GlobalStyle />
      <Outlet />
      <Footer />
    </>
  );
}
export default Home;
