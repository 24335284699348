import { useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import SelectYearMonthPopup from "../../components/calendar/SelectYearMonthPoup";
import YearMonthDropdown from "../../components/calendar/YearMonthDropdown";
import {
  currentDateState,
  datePopupState,
  yearMonthPopupState,
} from "../../recoil/atoms";
import Header from "../../components/layouts/Header";
import DateDropdown from "../../components/write/DateDropdown";
import { Flex } from "../../styles/landing";
import SelectDatePopup from "../../components/write/SelectDatePopup";
import DiaryTextArea from "../../components/write/DirayTextArea";
import LoadingPic from "../../components/write/LoadingPic";

function WriteDiary() {
  const location = useLocation();
  const setCurrentDate = useSetRecoilState(currentDateState);
  const yearMonthPopup = useRecoilValue(yearMonthPopupState);
  const datePopup = useRecoilValue(datePopupState);
  const [loading, setLoading] = useState(false);
  const { clickedDate } = location.state;

  useEffect(() => {
    setCurrentDate(clickedDate);
  }, []);

  return loading ? (
    <LoadingPic />
  ) : (
    <>
      <Header text="뒤로가기" />
      <Flex
        style={{
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <Flex style={{ marginLeft: "-20px", marginTop: "20px" }}>
            <YearMonthDropdown />
            <DateDropdown />
          </Flex>
          {datePopup && <SelectDatePopup />}
          {yearMonthPopup && <SelectYearMonthPopup />}
          <DiaryTextArea setLoading={setLoading} />
        </div>
      </Flex>
    </>
  );
}

export default WriteDiary;
