import styled from "styled-components";
import { Dispatch, SetStateAction, useState } from "react";
import { IconBox } from "./ScrapCategoryPopup";
import { BigBtn } from "../../pages/user/Signup";
import { postScrapCategory } from "../../apis/scrap-api";

const CategoryInput = styled.input`
  border: 0px solid;
  background-color: #fff;
  width: 319px;
  height: 32px;
  margin-top: 12px;
  padding-bottom: 4px;
`;
const AddBtn = styled(BigBtn)`
  position: relative;
  bottom: 0;
  margin-top: 12px;
`;
const Line = styled.div`
  height: 1px;
  width: 320px;
  background-color: #111;
`;
const SubTitle = styled.div`
  color: #727272;
  margin-top: 12px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  background-color: #fff;
`;
function MakeCategoryPopup({
  setPostCategoryPopup,
}: {
  setPostCategoryPopup: Dispatch<SetStateAction<boolean>>;
}) {
  const [isActive, setIsActive] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const handleCategory = (e: any) => {
    if (e.target.value.length > 0) setIsActive(true);
    else setIsActive(false);
    setCategoryName(e.target.value);
  };
  const handleMakeCategory = () => {
    postScrapCategory(categoryName).then(() => {
      setPostCategoryPopup(false);
    });
  };
  return (
    <IconBox>
      <h1>새 카테고리</h1>
      <div>
        <SubTitle>카테고리 이름</SubTitle>
        <CategoryInput onChange={handleCategory} />
        <Line />
      </div>
      <AddBtn onClick={handleMakeCategory} active={isActive}>
        저장
      </AddBtn>
    </IconBox>
  );
}

export default MakeCategoryPopup;
