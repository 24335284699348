import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import back from "../../assets/layouts/back.svg";

interface Iprop {
  text: string;
}
const Container = styled.div`
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
`;
const Back = styled.img`
  width: 28px;
  height: 28px;
`;
const Text = styled.div`
  margin-left: 8px;

  color: #111;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
`;
function Header({ text }: Iprop) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (localStorage.getItem("before") === "write") {
      navigate("/calendar");
      localStorage.setItem("before", "");
    } else navigate(-1);
  };
  return (
    <Container>
      <Back onClick={handleNavigate} src={back} alt="" />
      <Text>{text}</Text>
    </Container>
  );
}

export default Header;
