import styled from "styled-components";

import { Flex } from "../../styles/landing";
import { IreportInfo } from "../../pages/home/Report";
import logo from "../../assets/landing/Rectangle.png";

const StatusContainer = styled.div`
  padding: 50px;
  @keyframes swipeUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0);
    }
  }
  animation: swipeUp 0.5s;
  position: absolute;
  margin-top: 72px;
  width: 100%;
  min-height: 500px;
  flex-shrink: 0;
  border-radius: 30px 30px 0px 0px;

  background: #f8cfaa;

  display: flex;

  align-items: center;
  flex-direction: column;
`;

const StatusBox = styled(Flex)`
  margin-top: 16px;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 320px;

  margin-top: 12px;

  color: #524141;
  font-size: 14px;

  font-weight: 500;
`;
const Bar = styled.div`
  border-radius: 50px;
  background: #fefcfc;
  width: 220px;
  height: 27px;
  flex-shrink: 0;
`;
const BigBar = styled(Bar)`
  margin-top: 8px;
  margin-left: 0;
  width: 320px;
`;
const TotalBox = styled.div`
  padding: 30px;
  @keyframes swipeUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0);
    }
  }
  animation: swipeUp 1s;
  position: absolute;
  bottom: 60px;
  z-index: 1004;
  border-radius: 30px 30px 0px 0px;

  width: 100%;
  background: linear-gradient(180deg, #ffc9be 0%, #ffe9e5 36.5%, #fa9 100%);

  flex-shrink: 0;
`;
const TotalStatusBox = styled(TotalBox)`
  padding: 40px;
  padding-bottom: 80px;
  top: 320px;
  min-height: 1000px;
  padding-bottom: 120px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    align-self: start;
    margin-top: 12px;

    color: #524141;
    font-size: 14px;

    font-weight: 500;
  }
`;
const Text = styled.textarea`
  pointer-events: none;
  padding: 30px;
  margin-top: 40px;
  box-shadow: 10.494px 20.669px 3.18px 0px rgba(0, 0, 0, 0.25);
  width: 320px;
  min-height: 320px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px; /* 175% */
`;

const PpercentBar = styled.div<{ percent: number }>`
  border-radius: 50px;
  background: #fff2ae;
  height: 27px;

  width: ${(props) => `${props.percent}%`};
`;

const NpercentBar = styled(PpercentBar)`
  background: #e0dfec;
`;
// const Logo = styled.div`
//   @font-face {
//     font-family: "LOTTERIACHAB";
//     src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/LOTTERIACHAB.woff2")
//       format("woff2");
//     font-weight: normal;
//     font-style: normal;
//   }
//   font-family: "LOTTERIACHAB";

//   font-size: 30px;
//   position: absolute;
//   top: -34px;
//   color: #f97f34;
//   stroke: 1px solid;
// `;
const color = ["#FFF2AE", "#BEE7E9", "#E0DFEC"];
const emotions = [
  {
    name: "불안",
    percentage: 32,
  },
  {
    name: "우울",
    percentage: 24,
  },
  {
    name: "기쁨",
    percentage: 16,
  },
];
function ReportStatus({ reportInfo }: { reportInfo: IreportInfo }) {
  console.log(reportInfo);
  return (
    <>
      <StatusContainer>
        {/* <Logo>Moo:Doodle</Logo> */}
        <img
          style={{ position: "absolute", top: "-60px", height: "100px" }}
          src={logo}
          alt=""
        />
        {emotions.map((e, i) => {
          return (
            <StatusBox>
              {e.name}
              <Bar>
                <PpercentBar
                  style={{ background: color[i] }}
                  percent={e.percentage * 2}
                />
              </Bar>
            </StatusBox>
          );
        })}
      </StatusContainer>

      <TotalStatusBox>
        <div>
          <h1>종합 긍정지수</h1>
          <BigBar>
            <PpercentBar percent={30} />
          </BigBar>
        </div>
        <div>
          <h1>종합 부정지수</h1>
          <BigBar>
            <NpercentBar percent={70} />
          </BigBar>
        </div>
        <div style={{ marginTop: "32px" }}>
          <iframe
            width="320px"
            height="280px"
            src="https://www.youtube.com/embed/Kk7TQGqQ3nA?si=O-Mo90b-g-u2EG1-"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </div>
        <Text
          value={`스트레칭을 통해 우울한 감정을 환기시켜보세요!
몸을 움직이는 것은 우울감과 불안을 감소시키는 데 효과적이에요.
규칙적인 스트레칭은 스트레스 호르몬의 수치를 낮추는 데 도움을 주고, 일상생활에서 긍정적인 변화를 경험할 수 있어요.
스트레칭을 통해 몸과 마음의 건강을 함께 챙겨보세요!`}
        />
      </TotalStatusBox>
    </>
  );
}
export default ReportStatus;
