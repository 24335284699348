import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");
	* {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
		font-family: "Pretendard Variable";
		font-size: 14px;
	}

	ul,
	ol,
	li {
		list-style: none;
	}
    button{
        border: none;
        background: none;
        font-size: 16px;
		&:hover{
			cursor: pointer;
		}
    }
	p,img{
		margin: 0;
		padding: 0;
		background-color:transparent
	}
	input{
		border: 0;
		&:focus{
			outline: none;
		}
		
	}
	input:autofill{
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}
	html,
	body {
		background: #FEFCFC;

		font-size: 16px;
	}
	textarea{
		border: none;
		outline: none;
		resize: none;
	}
	a {
		text-decoration: none;
		color: inherit;
	}
`;

export default GlobalStyle;
