import styled from "styled-components";

import { useState } from "react";
import { useRecoilValue } from "recoil";
import { Flex } from "../../styles/landing";
import EditNicknamePopup from "./EditNicknamePopup";
import { IbasicInfo } from "../../pages/home/Mypage";
import { userInfoState } from "../../recoil/atoms";
import editImg from "../../assets/diary/editDiary.svg";

const BasicInfoContainer = styled(Flex)`
  width: 100%;
  height: 120px;
  flex-shrink: 0;

  justify-content: center;

  flex-direction: column;
  background-color: #fff;

  margin-top: 12px;
  padding: 24px;

  h1 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }
  p {
    margin-top: 8px;
    color: #939393;
    font-size: 15px;
    font-weight: 500;
  }
  button {
    border-radius: 10px;
    background: #eee;
    height: 40px;
    margin-top: 32px;
  }
`;
const EditImg = styled.img`
  width: 20px;
  height: 20px;
`;
function BasicInfoBox() {
  const [isEdit, setIsEdit] = useState(false);
  const basicInfo = useRecoilValue<IbasicInfo>(userInfoState);
  return (
    <>
      <BasicInfoContainer>
        <Flex style={{ gap: "4px" }}>
          <h1>{basicInfo.nickname || "닉네임 없음"} </h1>
          <EditImg
            onClick={() => {
              setIsEdit(true);
            }}
            src={editImg}
            alt=""
          />
        </Flex>
        <p>{basicInfo.email || "이메일 없음"}</p>
      </BasicInfoContainer>
      {isEdit && <EditNicknamePopup setIsEdit={setIsEdit} />}
    </>
  );
}
export default BasicInfoBox;
