import styled from "styled-components";

import { useEffect, useState } from "react";
import ScrapCategoryBox from "../../components/scrap/ScrapCategoryBox";
import ScrapHeader from "../../components/scrap/ScrapHeader";
import { getScrapCategories } from "../../apis/scrap-api";
import { Flex } from "../../styles/landing";

const CategoryListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px;

  padding-bottom: 100px;

  justify-content: center;
`;

export interface IscrapCategory {
  categoryId: number;
  name: string;
  imageUrl: string;
  scrapCount: number;
}
function ScrapCategoryList() {
  const [scrapCategory, setScrapCategory] = useState<IscrapCategory[]>([]);
  useEffect(() => {
    getScrapCategories()
      .then((res) => {
        setScrapCategory(res.data.result.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const isOdd = scrapCategory.length % 2 !== 0;

  return (
    <>
      <ScrapHeader text="스크랩" />
      <Flex style={{ justifyContent: "center" }}>
        <CategoryListContainer>
          {scrapCategory.length !== 0
            ? scrapCategory.map((e) => {
                return (
                  <ScrapCategoryBox
                    scrapCategory={e}
                    setScrapCategory={setScrapCategory}
                  />
                );
              })
            : "카테고리가 존재하지 않습니다."}
          {isOdd && <div style={{ width: "152px", height: "152px" }} />}
        </CategoryListContainer>
      </Flex>
    </>
  );
}
export default ScrapCategoryList;
