/* eslint-disable import/extensions */

import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { RecoilRoot } from "recoil";
import router from "./Router";

import serviceWorkerRegistration from "./serviceWorkerRegistration"; // 서비스 워커 등록을 위한 파일

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <RecoilRoot>
    <RouterProvider router={router} />
  </RecoilRoot>,
);
serviceWorkerRegistration();
