import { useRecoilValue } from "recoil";
import { useState } from "react";
import { EditBox } from "../../pages/home/Mypage";
import { Flex } from "../../styles/landing";
import { userInfoState } from "../../recoil/atoms";
import EditAlarmPopup from "./EditAlarmPopup";

function AlarmBox() {
  const userInfo = useRecoilValue(userInfoState);
  const [isEdit, setIsEdit] = useState(false);
  return (
    <>
      <EditBox
        onClick={() => {
          setIsEdit(true);
        }}
      >
        <p>알림 시간 설정</p>
        <Flex style={{ gap: "8px" }}>
          <p style={{ color: "#FDA594" }}>{userInfo.notificationTime}</p>
          {`${">"}`}
        </Flex>
      </EditBox>
      {isEdit && <EditAlarmPopup setIsEdit={setIsEdit} />}
    </>
  );
}

export default AlarmBox;
