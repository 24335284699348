import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { postDiary } from "../../apis/calandar-api";

const SelectPicBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  margin-top: 40px;
  gap: 16px;
  align-items: center;
`;
const PicImg = styled.img`
  width: 152px;
  height: 152px;
  flex-shrink: 0;
  border-radius: 8px;
`;
const SelectPicContainer = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-top: 40px;
    color: #111;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
  h2 {
    margin-top: 4px;
    color: var(--Font-04_Gray, #767676);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }
`;
function WriteSelectPic() {
  const navigate = useNavigate();
  const location = useLocation();
  const imagesArr = location.state.imageUrls;
  const { date } = location.state;
  const { diaryContent } = location.state;
  const handlePostDiary = (imageUrl: string) => {
    postDiary({
      date,
      content: diaryContent,
      imageUrl,
    })
      .then((res) => {
        localStorage.setItem("before", "write");
        navigate(`/diary/${res.data.result}`);
        alert("등록이 완료되었습니다.");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(location.state);
  return (
    <SelectPicContainer>
      <h1>그림을 선택해 주세요</h1>
      <h2 style={{ marginTop: "20px" }}>오늘 하루를 가장 잘 나타낸 그림을</h2>
      <h2> 자유롭게 선택해 보세요!</h2>
      <SelectPicBox>
        {imagesArr.map((e: string) => {
          return <PicImg onClick={() => handlePostDiary(e)} src={e} />;
        })}
      </SelectPicBox>
    </SelectPicContainer>
  );
}

export default WriteSelectPic;
