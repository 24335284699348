import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Flex } from "../../styles/landing";
import { IDiaryInfo } from "../../apis/calandar-api";

export const ListBox = styled(Flex)`
  width: 100%;

  border-bottom: 1px solid #dbdbdb;
  padding: 20px;
  align-items: center;

  p {
    color: #575757;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 275% */
    letter-spacing: -0.2px;
  }
  h3 {
    color: var(--Font-02_black, #111);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 183.333% */
    letter-spacing: -0.3px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    width: 260px;
  }
`;

export const TmpImg = styled.img`
  width: 52px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 10px;
  background: url(<path-to-image>) lightgray 50% / cover no-repeat;
`;

export const TextBox = styled.div`
  margin-left: 12px;
`;
function DiaryListBox({ info }: { info: IDiaryInfo }) {
  const navigate = useNavigate();
  console.log(info);
  const handleViewDiary = () => {
    navigate(`/diary/${info.id}`);
  };
  return (
    <ListBox onClick={handleViewDiary}>
      <TmpImg src={info.imageUrl} />

      <TextBox>
        <h3>{info.content}</h3>
        <p>{info.date}</p>
      </TextBox>
    </ListBox>
  );
}
export default DiaryListBox;
