import styled from "styled-components";

import { useRecoilState, useSetRecoilState } from "recoil";
import { Flex } from "../../styles/landing";
import { currentDateState, yearMonthPopupState } from "../../recoil/atoms";
import { Overlay } from "../diary/HamburgerPopup";

const DateContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
const YearBox = styled(Flex)`
  justify-content: space-around;
  align-items: center;
  p {
    font-size: 20px;
  }
`;
export const DateBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 8px;
  width: 58px;
  height: 32px;
  border: 1px solid var(--Line-Light_Color, #acabab);
  border-radius: 12px;
  color: #797979;

  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 181.818% */
  letter-spacing: -0.275px;
`;
export const SelctPopupBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #f1f1f5;
  padding: 16px;
  padding-top: 24px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1500;
  width: 340px;
  height: 240px;
  border: 1px solid var(--Line-Light_Color, #f1f1f5);
  border-radius: 12px;

  h1 {
    color: #111;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px; /* 181.818% */
    letter-spacing: -0.275px;
  }
`;
export const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1005;
`;
function SelectYearMonthPopup() {
  const [currentDate, setCurrentDate] = useRecoilState(currentDateState);
  const setYearMonthPopupState = useSetRecoilState(yearMonthPopupState);
  const handleCurrentDate = (month: number) => {
    setCurrentDate(new Date(currentDate.getFullYear(), month - 1, 1));
    setYearMonthPopupState(false);
  };
  const handleCurrentYear = (e: any) => {
    if (e.target.innerText === "-")
      setCurrentDate(
        new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1),
      );
    else
      setCurrentDate(
        new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), 1),
      );
  };
  const renderDateBox = () => {
    const dateBoxes = [];
    for (let i = 1; i <= 12; i += 1) {
      dateBoxes.push(
        <DateBtn key={i} onClick={() => handleCurrentDate(i)}>
          {i}월
        </DateBtn>,
      );
    }
    return dateBoxes;
  };
  return (
    <>
      <Overlay
        onClick={() => {
          setYearMonthPopupState(false);
        }}
      />
      <SelctPopupBox>
        <YearBox>
          <button onClick={handleCurrentYear}>-</button>
          <h1>{currentDate.getFullYear()}년</h1>
          <button onClick={handleCurrentYear}>+</button>
        </YearBox>
        <DateContainer style={{ flexWrap: "wrap" }}>
          {renderDateBox()}
        </DateContainer>
      </SelctPopupBox>
    </>
  );
}
export default SelectYearMonthPopup;
