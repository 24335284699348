import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Dispatch, SetStateAction } from "react";
import { ListBox, TextBox, TmpImg } from "../scrap/DiaryListBox";
import plusImg from "../../assets/scrap/plus.svg";
import checkImg from "../../assets/diary/checkImg.svg";
import { getScrapId, postScrapToCategory } from "../../apis/scrap-api";

const Name = styled.div`
  color: #000;
  text-align: start;
  font-size: 14px;
  font-weight: 600;
`;
const Count = styled.div`
  color: #929292;
  text-align: start;
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
`;

const CategoryPlus = styled.img`
  margin-left: auto;
  border: 1px solid;
  border-radius: 20px;
`;
function CategoryListBox({
  info,
  setIsScrap,
}: {
  info: any;
  setIsScrap: Dispatch<SetStateAction<boolean>>;
}) {
  const param = useParams();

  const handleAddScrapToCategory = () => {
    console.log(param.id);
    getScrapId(Number(param.id))
      .then((res) => {
        postScrapToCategory(res.data.result, info.categoryId).then(() => {
          setIsScrap((prev) => !prev);
        });
      })
      .catch(() => {
        alert("스크랩을 먼저 진행해주세요!");
      });
  };
  return (
    <ListBox>
      <TmpImg src={info.imageUrl} />
      <TextBox>
        <Name>{info.name}</Name>
        <Count>{info.scrapCount}</Count>
      </TextBox>
      {info.isScrapped ? (
        <CategoryPlus onClick={handleAddScrapToCategory} src={checkImg} />
      ) : (
        <CategoryPlus onClick={handleAddScrapToCategory} src={plusImg} />
      )}
    </ListBox>
  );
}
export default CategoryListBox;
