import { useRecoilState } from "recoil";
import { useState } from "react";
import {
  Dropdown,
  DropdownList,
  DropdownOption,
  DropdownSelected,
} from "../../pages/user/SignupAlarm";
import { minuteState } from "../../recoil/atoms";

function MinuteDropdown() {
  const [minute, setMinute] = useRecoilState<string>(minuteState);
  const [minuteOpen, setMinuteOpen] = useState<boolean>(false);
  return (
    <Dropdown onClick={() => setMinuteOpen(!minuteOpen)}>
      <DropdownSelected>{`${minute}분`}</DropdownSelected>
      <DropdownList isActive={minuteOpen}>
        <DropdownOption
          onClick={() => {
            setMinute("00");
            setMinuteOpen(false);
          }}
        >
          00분
        </DropdownOption>
        <DropdownOption
          onClick={() => {
            setMinute("30");
            setMinuteOpen(false);
          }}
        >
          30분
        </DropdownOption>
      </DropdownList>
    </Dropdown>
  );
}

export default MinuteDropdown;
